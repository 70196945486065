@charset "utf-8";

.p-require{

  .mv {
    background-image: url(#{$imgPath}pages/recruitment/require/mv.jpg);
    background-position: 50% top;
    @include sp {
      min-height: 312px;
      padding-bottom: 0;
      background-image: url(#{$imgPath}pages/recruitment/require/mv_sp.jpg);
      background-position: right 80%;
      background-size: cover;
    }

    &-ttl {
      line-height: 1.17;
      @include sp {
        position: absolute;
        top: 40px;
        padding: 0 30px;
        font-size: 4.8rem;
        span {
          display: block;
          margin-top: 6px;
          margin-left: 0;
          letter-spacing: normal;
        }
      }
    }
  }

  .require-btn {
    display: block;
    max-width: 500px;
    margin: 40px auto 0;
    @include sp {
      margin: 32px auto 0;
    }
  }

  .intro {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 48px;
    }

    &-txt {
      text-align: center;
      @include sp {
        width: 84%;
        margin: 32px auto 0;
      }

    }
  }

  .midway {
    padding: 80px 0;
    background-color: rgba(0,0,0, 0.05);
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl {
      font-size: 3.6rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        font-size: 2.8rem;
      }
    }

    .sec-txt {
      margin-top: 42px;
      text-align: center;
      @include sp {
        margin-top: 32px;
      }
    }
  }
}
