@charset "utf-8";

.p-index {
  overflow: hidden;
  .mv {
    position: relative;
    &::before {
      content:"";
      position: absolute;
      z-index: 5;
      bottom: 100px;
      left: 0;
      width: 100%;
      height: calc(100% - 95px);
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22627.827%22%20height%3D%22525.987%22%20viewBox%3D%220%200%20627.827%20525.987%22%3E%20%3Cg%20id%3D%22logo_mark%22%20opacity%3D%220.302%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_113%22%20data-name%3D%22%E3%83%91%E3%82%B9%20113%22%20d%3D%22M889.877%2C535.36%2C867.667%2C640.78h240.3l1.54%2C105.534H740.561L652.2%2C456.152h-7.755l-58.9%2C290.162H481.676L582.436%2C224.979H690.951L780.878%2C515.1h10.833l51.151-294.771h263.561V324.292h-172.1L911.746%2C431.5h196.217V532.164Z%22%20transform%3D%22translate(-481.676%20-220.327)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-size: contain;
      @include sp {
        content: none;
      }
    }

    &-video {
      width: 100%;
      vertical-align: bottom;
    }

    &-ttl {
      position: absolute;
      z-index: 5;
      top: 50%;
      left: percentage(100/1440);
      font-size: 6.4rem;
      font-weight: bold;
      line-height: 1;
      transform: translateY(-50%);
      color: #fff;
      @include mq(large) {
        font-size: 4rem;
        @include sp {
          top: 60px;
          left: 16px;
          font-size: 3.1rem;
          transform: none;
        }
      }
      &_en {
        display: block;
        margin-top: 30px;
        font-family: $archivo;
        font-size: 2rem;
        @include mq(large) {
          margin-top: 24px;
          font-size: 1.6rem;
          @include sp {
            margin-top: 11px;
            font-size: 1.3rem;
          }
        }
      }
    }

    .info {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      max-width: 810px;
      height: 100px;
      @include sp {
        position: static;
        height: 93px;
      }

      &-inner {
        overflow: hidden;
      }

      &-ttl {
        display: flex;
        width: 150px;
        padding: 0 18px;
        font-family: $archivo;
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        background-color: #000;
        align-items: center;
        justify-content: center;
        @include sp {
          width: percentage(102/375);
          padding: 0 15px;
          font-size: 1rem;
          letter-spacing: 0.08em;
        }
      }

      &-content {
        display: flex;
        overflow: hidden;
        width: 100%;
        max-width: 660px;
        padding-left: 30px;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        @include sp {
          padding-left: 16px;
        }
      }

      .swiper-slide {
        display: flex;
        align-items: center;
      }

      &-time {
        display: inline-block;
        margin-right: 24px;
        font-size: 1.3rem;
        color: $subttlColor;
        @include sp {
          line-height: 1;
        }
      }

      &-tag {
        display: inline-block;
        font-size: 1.3rem;
        color: #000;
        @include sp {
          line-height: 1;
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      &-post__ttl {
        display: -webkit-box;
        margin-top: 12px;
        font-size: 1.6rem;
        color: #000;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        @include sp {
          margin-top: 0;
          font-size: 1.3rem;
          line-height: 1.5;
          -webkit-line-clamp: 2;
        }

        &.link {
          text-decoration: underline;
        }
      }

      .paging {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 16px;
        height: 45px;
        margin: 0 24px;
        justify-content: space-between;
        @include sp {
          margin: 0 16px;
        }
        &::before {
          content:"";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 1px;
          transform: translate(-50%, -50%);
          background-color: #99a0a2;
        }

        .info-button {
          position: relative;
          display: block;
          width: 16px;
          height: 16px;
          outline: none;
          &:hover {
            cursor: pointer;
          }
          &::before {
            content:"";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 9px;
            border-top: 1px solid #333;
            border-left: 1px solid #333;
          }
          &.prev {
            &::before {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
          &.next {
            &::before {
              transform: translate(-50%, -50%) rotate(-135deg);
            }
          }
        }
        .swiper-button-disabled {
          pointer-events: none;
          &::before {
            border-color: #99a0a2;
          }
        }
      }
    }

    .mvSwiper {
      min-height: 620px;
      @include sp {
        min-height: auto;
      }
      .swiper-slide {
        img {
          object-fit: cover;
          object-position: 100% 100%;
          min-height: 620px;
          transition: .7s;
          @include sp {
            min-height: auto;
            object-position: 50% 50%;
          }
        }
      }

      @keyframes zoomUp {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.05);
        }
      }
      @keyframes zoomDown {
        0% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }

      .swiper-slide-active img,
      .swiper-slide-duplicate-active img,
      .swiper-slide-prev img {
        animation: zoomUp 7s linear 0s 1 normal both;
      }

      .paginationArea {
        position: absolute;
        z-index: 5;
        right: 0;
        bottom: 0;
        left: auto;
        display: flex;
        width: 190px;
        height: 100px;
        padding: 0 20px;
        background-color: #000;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width:1000px) {
          display: none;
        }

        .arrow {
          position: relative;
          width: 20px;
          height: 20px;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            width: 8px;
            height: 12px;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .prev::before {
          background-image: url(#{$imgPath}common/prev.svg);
        }
        .next::before {
          background-image: url(#{$imgPath}common/next.svg);
        }

        .mv-pagination {
          display: flex;
          width: 60%;
          justify-content: space-between;
        }

        .swiper-pagination-bullet {
          width: 32%;
          height: 2px;
          transition-duration: .7s;
          border-radius: 0;
          background-color: #99a0a2;
          background-color: #fff;
          flex: 1;
          &:not(:last-child) {
            margin-right: 3%;
          }
        }
      }
    }
  }

  .sec {
    position: relative;
    display: flex;
    min-height: 550px;
    padding: 0 16px;
    background-size: cover;
    align-items: center;
    @include sp {
      min-height: auto;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-inner {
      width: 100%;
      max-width: 1160px;
      margin: 0 auto;
      @include sp {
        padding: 72px 0 50px;
      }
    }

    &-contents {
      width: 580px;
      padding: 44px 40px;
      background-color: #fff;
      box-shadow: 10px 20px 20px 0 rgba(0, 0, 0, 0.2);
      @include sp {
        width: 100%;
        padding: 48px 16px;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
      }

      &.right {
        margin-left: auto;
      }
      &.left {
        margin-right: auto;
      }
    }

    &-subttl {
      margin-top: 16px;
    }

    &-txt {
      margin-top: 32px;
    }

    &-btn {
      margin-top: 32px;
      @include sp {
        margin: 32px auto 0;
      }
    }
    &.about {
      background-image: url(#{$imgPath}pages/index/sec01_bg.jpg);
    }
    &.business {
      background-image: url(#{$imgPath}pages/index/sec02_bg.jpg);
    }
    &.products {
      background-image: url(#{$imgPath}pages/index/sec03_bg.jpg);
    }
    &.about,
    &.business,
    &.products {
      z-index: 1;
      overflow: hidden;
      // 背景画像のセクションは、アスペクト比可変
      &::before {
        content:"";
        display: block;
        padding-top: (55/144 * 100%);
        @include sp {
          content: none;
        }
      }

      //背景画像モノクロ
      &::after {
        content:"";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: 1s;
        background: inherit;
        filter:grayscale(100%)brightness(70%)contrast(140%);
      }

      &.is-active {
        &::after {
          transform: scale(1.03);
          filter: none;
        }
      }
    }

    &.movie {
      padding: 80px 20px;
      background-color: rgba(0,0,0,0.05);
      @include sp {
        padding: 74px 0;
        background-color: rgba(0, 0, 0, 0.05);
      }
      .sec-inner {
        display: flex;
        justify-content: space-between;
        @include sp {
          display: block;
          padding: 0 16px 48px;
          background-color: #eaeaea;
        }
      }
      .sec-col {
        display: block;
        &:first-child {
          margin-top: 50px;
          margin-right: 20px;
          @include sp {
            margin-top: 0;
            margin-right: 0;
          }
        }

        &:last-child {
          width: percentage(720/1160);
        }

        .sec-ttl {
          margin-top: -24px;
        }
      }
      .js-modal-video {
        position: relative;
        box-shadow: 10px 20px 20px 0 rgba(0, 0, 0, 0.2);
        @include sp {
          margin-top: 32px;
        }
      }
    }

    &.factory {
      position: relative;
      display: block;
      overflow: hidden;
      min-height: 616px;
      padding: 80px 20px;
      background-color: #000;
      @include sp {
        min-height: 425px;
        padding: 48px 0;
      }
      &::before {
        content: none;
      }

      .sec-inner {
        @include sp {
          padding: 0;
        }
      }

      .sec-ttl,
      .sec-subttl {
        text-align: right;
        color: #fff;
        @include sp {
          text-align: center;
        }
      }


      .factory-bg {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 60%;
        min-width: 742px;
        height: calc(100% + 6px);
        pointer-events: none;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22627.827%22%20height%3D%22525.987%22%20viewBox%3D%220%200%20627.827%20525.987%22%3E%20%3Cg%20id%3D%22logo_mark%22%20opacity%3D%220.302%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_113%22%20data-name%3D%22%E3%83%91%E3%82%B9%20113%22%20d%3D%22M889.877%2C535.36%2C867.667%2C640.78h240.3l1.54%2C105.534H740.561L652.2%2C456.152h-7.755l-58.9%2C290.162H481.676L582.436%2C224.979H690.951L780.878%2C515.1h10.833l51.151-294.771h263.561V324.292h-172.1L911.746%2C431.5h196.217V532.164Z%22%20transform%3D%22translate(-481.676%20-220.327)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-size: contain;
        @include sp {
          top: -5px;
          bottom: auto;
          width: 228px;
          min-width: auto;
        }
      }

      .factorySwiper {
        z-index: 10;
        width: 100vw;
        margin: 32px calc(50% - 50vw) 0;
        pointer-events: none;
        .swiper-wrapper {
          transition-timing-function: linear !important;
        }
        .swiper-slide {
          min-width: 375px;
          margin-right: -32px;
          @include sp {
            width: 187px;
            min-width: auto;
            margin-right: -17px;
          }
        }
      }

      .sec-btn {
        position: relative;
        z-index: 10;
        margin: 32px auto 0;
      }
    }

    &.recruit {
      min-height: 480px;
      background-image: url(#{$imgPath}pages/index/sec06_bg.jpg);
      background-position: 70% 50%;
      @include sp {
        min-height: auto;
        padding: 0;
        background-image: url(#{$imgPath}pages/index/sec06_bg_sp.jpg);

        @media screen and (max-width:375px) {
          background-size: cover;
        }
      }
      &::before {
        content:"";
        display: block;
        padding-top: (1/3 * 100%);
        @include sp {
          padding-top: (512/375 * 100%);
        }
      }

      .sec-inner {
        @include sp {
          margin: 0 16px;
          padding: 48px 16px;
          background-color: rgba(255, 255, 255, 0.9);
        }
      }

      .sec-txt {
        &:nth-of-type(3) {
          margin-top: 8px;
          @include sp {
            margin: 0;
          }
        }
        span {
          display: inline-block;
          padding: 0 10px;
          background-color: #fff;
          @include sp {
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
  }

  .HaP {
    min-height: auto;
    padding: 50px 16px;
    @include mq(large) {
      background-color: rgba(0,0,0,0.05);
    }
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq(large) {
        display: block;
        @include sp {
          padding-top: 0;
        }
      }
    }

    &-logo {
      max-width: 467px;
      @include mq(large) {
        margin: 12px auto 0;
      }
    }

    &-ttl {
      font-size: 3.4rem;
      font-weight: bold;
      @include mq(large) {
        text-align: center;
        @include sp {
          font-size: 2.4rem;
          line-height: 1.25;
        }
      }
    }

    &-txt {
      font-size: 1.6rem;
    }

    &-list {
      li {
        padding-left:1em;
        font-size: 1.4rem;
        text-indent:-1em;
      }
    }

    &-link {
      text-align: right;
      a {
        text-decoration: underline #0262b4;
        color: #0262b4;
      }
    }

    &-box {
      display: flex;
      margin-top: 24px;
      column-gap: percentage(40/692);
      align-items: flex-start;
      @include mq(large) {
        display: block;
        max-width: 800px;
        margin: 24px auto 0;
        @include sp {
          width: percentage(294/343);
          font-size: 1.6rem;
        }
      }
    }

    &-img {
      max-width: 162px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    }
  }
}
