@charset "utf-8";

.p-recruitment {

  .mv {
    background-image: url(#{$imgPath}pages/recruitment/mv.jpg);
    background-position: 50% top;
    @include sp {
      min-height: 200px;
      padding-bottom: 0;
      background-image: url(#{$imgPath}pages/recruitment/mv_sp.jpg);
      background-position: right 80%;
      background-size: cover;
    }

    &-ttl {
      @include sp {
        position: absolute;
        top: 40px;
        padding: 0 30px;
        font-size: 4.8rem;
        line-height: 1;
        span {
          display: block;
          margin-top: 6px;
          margin-left: 0;
          letter-spacing: normal;
        }
      }
    }
  }

  .numbers {
    padding: 80px 16px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
    @include sp {
      padding: 48px 16px;
    }

    &-list {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      @include sp {
        display: block;
      }

      li {
        width: percentage(360/1160);
        padding: 40px 24px;
        background-color: #fff;
        @include sp {
          width: 100%;
          padding: 32px 24px;
          &:nth-child(n+2) {
            margin-top: 16px;
          }
        }
      }

      &__ttl {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.5;
      }

      &__value {
        margin-top: 17px;
        font-size: 5.6rem;
        font-weight: bold;
        line-height: 1;
        vertical-align: middle;
        color: $btnColor;
        @include sp {
          margin-top: 20px;
        }
        span {
          font-size: 2.8rem;
          font-weight: bold;
        }
      }

      li:nth-child(1) > .numbers-list__value:before {
        content:url(#{$imgPath}pages/recruitment/number-icon01.svg);
        padding-right: 16px;
      }
      li:nth-child(2) > .numbers-list__value:before {
        content:url(#{$imgPath}pages/recruitment/number-icon02.svg);
        padding-right: 16px;
      }
      li:nth-child(3) > .numbers-list__value:before {
        content:url(#{$imgPath}pages/recruitment/number-icon03.svg);
        padding-right: 16px;
      }

      &__note {
        margin-top: 19px;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        @include sp {
          margin-top: 14px;
        }
      }

      &__txt {
        margin-top: 24px;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.85;
      }
    }
  }

  .staff {
    padding: 80px 16px;
    text-align: center;
    @include sp {
      padding: 48px 16px;
    }
    &-list {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      @include sp {
        display: block;
        margin-top: 32px;
      }

      li {
        position: relative;
        width: percentage(360/1160);
        text-align: left;
        @include sp {
          width: 100%;
          &:nth-child(n+2) {
            margin-top: 33px;
          }
        }

        &::before {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          padding: 4px 12px;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.5;
          color: #fff;
          background-color: $btnColor;
        }
        &:nth-child(1)::before {
          content:"営業部";
        }
        &:nth-child(2)::before {
          content:"設計部 設計1課";
        }
        &:nth-child(3)::before {
          content:"製造2部 組立1課";
        }

        a{
          display: block;
          overflow: hidden;
          img {
            transition: 0.7s;
          }
          &:hover {
            img {
              transform: scale(1.05);
            }
          }
        }

        h3 {
          margin-top: 24px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: normal;
          @include sp {
            margin-top: 12px;
          }
          span {
            display: inline-block;
            margin-left: 16px;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .faq {
    padding: 80px 16px;
    background-color: rgba(0, 0, 0, 0.05);
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl,
    .sec-subttl {
      text-align: center;
    }

    .accordion-area {
      margin-top: 40px;
      @include sp {
        margin-top: 32px;
      }
    }

    .js-accordion {
      background-color: #fff;

      &:nth-child(n+2) {
        margin-top: 16px;
      }

      &-Btn {
        position: relative;
        margin: 0 24px;
        padding: 24px 0;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: normal;
        cursor: pointer;
        @include sp {
          margin: 0 16px;
          padding: 24px 40px 24px 30px;
        }
        &::before,
        &::after {
          content:"";
          position: absolute;
          top: 50%;
          right: 0;
          width: 24px;
          height: 2px;
          transition: .3s;
          transform: translateY(-50%);
          background-color: $btnColor;
        }
        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
        &.is-active::after {
          transform: translateY(-50%);
        }
        span {
          margin-right: 16px;
          font-size: 1.8rem;
          font-weight: bold;
          color: $btnColor;
          @include sp {
            position: absolute;
            top: 24px;
            left: 0;
            margin-right: 0;
          }
        }
      }
      &-Cont {
        display: none;
        margin: 0 24px;
        padding: 24px 0;
        font-size: 1.6rem;
        line-height: 1.875;
        border-top: 1px solid #dedede;
      }
    }
  }

  .button {
    margin: 60px auto;
    @include sp {
      margin: 40px auto;
      padding: 0 16px;
    }
    span {
      font-family: $baseFont;
      font-weight: bold;
    }
  }
}
