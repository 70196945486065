@charset "utf-8";

.p-recruitment-staff {

  .mv {
    background-image: url(/_assets/images/pages/recruitment/01/mv.jpg);
    @include sp {
      min-height: 200px;
      padding-bottom: 0;
      background-image: url(#{$imgPath}pages/recruitment/01/mv_sp.jpg);
      background-position: right 80%;
      background-size: cover;
    }
    &-ttl {
      @include sp {
        position: absolute;
        top: 40px;
        padding: 0 30px;
        font-size: 4.8rem;
        line-height: 1;
        span {
          display: block;
          margin-top: 6px;
          margin-left: 0;
          font-size: 1.6rem;
          letter-spacing: normal;
        }
      }
    }
  }

  .profile {
    margin-top: 40px;
    padding: 0 20px;
    @include sp {
      position: relative;
      z-index: 2;
      margin-top: -40px;
      padding: 0 16px;
    }
    .sec-inr {
      display: flex;
      @include sp {
        display: block;
      }
    }

    &-img {
      width: 360px;
      margin-right: percentage(60/1160);
      @include sp {
        width: 100%;
        margin-top: 17px;
        margin-right: 0;
      }
    }

    &-content {
      flex: 1;
      margin-top: 38px;
      @include sp {
        margin-top: 24px;
      }
    }

    &-ttl {
      padding-top: 48px;
      font-size: 2.8rem;
      font-weight: bold;
      background-color: #fff;
      @include sp {
        font-size: 2.4rem;
        line-height: 1.67;
        text-align: center;
      }
    }

    &-tag {
      display: inline-block;
      margin-top: 25px;
      padding: 4px 12px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      color: #fff;
      background-color: $btnColor;
      @include sp {
        margin-top: 0;
      }
    }

    &-name {
      margin-top: 12px;
      padding-bottom: 24px;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: normal;
      border-bottom: 1px solid #dedede;
      @include sp {
        margin-top: 16px;
        font-size: 2.4rem;
      }
      .kana {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .hireDate {
        display: inline-block;
        margin-left: 16px;
        font-size: 1.8rem;
        font-weight: normal;
        @include sp {
          margin-top: 10px;
          margin-left: 0;
          font-size: 1.6rem;
          letter-spacing: normal;
        }
      }
    }

    &-txt {
      margin-top: 24px;
      font-size: 1.6rem;
      line-height: 1.88;
      span {
        display: block;
        margin-bottom: 12px;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        color: $btnColor;
      }
    }
  }

  .staff-faq {
    position: relative;
    margin-top: 80px;
    counter-reset: number 0;
    @include sp {
      margin-top: 210px;
    }
    dl {
      dt {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1;
        @include sp {
          font-size: 2.4rem;
        }
        &::before {
          content: "Q" counter(number);
          margin-right: 16px;
          font-family: $archivo;
          font-size: 3.6rem;
          counter-increment: number 1;
          color: $btnColor;
          @include sp {
            display: block;
            margin-right: 0;
            margin-bottom: 14px;
            font-size: 2.8rem;
          }
        }
      }
      dd {
        margin-top: 16px;
        font-size: 1.6rem;
        line-height: 1.88;
        @include sp {
          margin-top: 18px;
        }
      }
    }

    .col2 {
      position: relative;
      align-items: center;
      display: flex;
      padding: 74px 0;
      @include sp {
        flex-direction: column-reverse;
        padding: 0;
      }
      &-content {
        max-width: 1160px;
        margin: 0 auto;
        @include sp {
          padding: 0 16px;
        }

        dl {
          width: percentage(520/1160);
          @include sp {
            width: 100%;
            margin-top: 32px;
          }
          &:nth-child(n+2) {
            margin-top: 40px;
            @include sp {
              margin-top: 32px;
            }
          }
        }
      }

      &-img {
        position: absolute;
        width: percentage(720/1440);
        max-width: 720px;
        @include sp {
          position: static;
          width: 100%;
          padding: 0 16px;
        }
      }

      &.left {
        padding-left: 20px;
        @include sp {
          padding-bottom: 48px;
          padding-left: 0;
        }
        &::before {
          content:"";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: calc(50vw + 80px);
          height: 100%;
          background-color: rgba(0,0,0,0.05);
          @include sp {
            width: 100%;
          }
        }

        .col2-img {
          top: calc(50% + 80px);
          left: 50%;
          transform: translateY(-50%);
          @include sp {
            margin-top: -162px;
            transform: none;
          }
        }
      }

      &.right {
        position: relative;
        margin-top: 166px;
        padding: 0 20px 0 0;
        @include sp {
          margin-top: 0;
          padding-right: 0;
        }
        .col2-content {
          dl {
            margin-left: auto;
          }
        }

        .col2-img {
          top: 50%;
          right: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .col1 {
      margin-top: 140px;
      padding: 0 20px;
      @include sp {
        margin-top: 48px;
        padding: 0 16px;

        dt {
          margin-top: 32px;
        }
      }
    }
  }


  .staff-link {
    margin-top: 184px;
    padding: 60px 20px;
    background-color: #f2f2f2;
    @include sp {
      margin-top: 48px;
      padding: 48px 16px;
    }
    &__inr {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      flex-wrap: wrap;
      @include sp {
        gap: normal;
        row-gap: 16px;
      }
      li {
        position: relative;
        width: 560px;
        margin: 0 auto;
        @include sp {
          width: 343px;
        }
        &::before {
          content: "";
          position: absolute;
          z-index: 2;
          top: 50%;
          right: 21px;
          width: 10px;
          height: 10px;
          transition: all .3s;
          transform: translateY(-50%) rotate(45deg);
          border-top: 2px solid #0262b4;
          border-right: 2px solid #0262b4;
        }

        &:hover {
          img {
            transform: scale(1.05);
          }

          &::before {
            transform: translate(3px, -50%) rotate(45deg);
          }
        }

        a {
          display: flex;
          height: 150px;
          align-items: center;
          @include sp {
            height: 120px;
          }
        }
      }
    }

    &__img {
      overflow: hidden;
      width: 250px;
      @include sp {
        width: auto;
      }

      img {
        transition: all.6s;
        @include sp {
          width: 130px;
          height: 120px;
          object-fit: cover;
        }
      }
    }

    &__info {
      height: 100%;
      padding: 38px 32px;
      background-color: #fff;
      flex: 1;
      @include sp {
        height: 120px;
        padding: 14px 16px;
      }

      &-tag {
        display: inline-block;
        padding: 4px 12px;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.5;
        color: #fff;
        background-color: $btnColor;
      }

      &-name {
        margin-top: 16px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #000;
        @include sp {
          margin-top: 8px;
          line-height: normal;
        }
        span {
          margin-left: 16px;
          font-size: 1.4rem;
          @include sp {
            display: block;
            margin-top: 6px;
            margin-left: 0;
            line-height: normal;
          }
        }
      }
    }
  }

  .recruitment-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 760px;
    margin: 40px auto;
    gap: 40px;
    flex-wrap: wrap;
    @include sp {
      display: block;
      padding: 0 16px;

      li:nth-child(n+2) {
        margin-top: 16px;
      }
    }
    a {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}
