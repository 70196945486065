@charset "utf-8";

.p-business {

  .mv {
    background-image: url(#{$imgPath}pages/business/mv.jpg);
  }

  .concept {
    padding: 80px 16px;
    background-color: rgba(0,0,0,0.05);
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl,
    .sec-subttl {
      text-align: center;
    }

    &-list {
      display: flex;
      margin-top: 40px;
      column-gap: percentage(40/1160);
      row-gap: 40px;
      flex-wrap: wrap;
      @include sp {
        display: block;
        margin-top: 32px;
      }
    }

    &-item {
      width: percentage(560/1160);
      padding: 40px;
      background-color: #fff;
      @include sp {
        width: 100%;
        padding: 40px 16px;
        &:nth-child(n+2) {
          margin-top: 24px;
        }
      }
      &__ttl {
        font-family: $archivo;
        font-size: 3.2rem;
        line-height: 1;
      }

      &__txt {
        margin-top: 24px;
        font-size: 1.6rem;
        line-height: 1.88;
        @include sp {
          margin-top: 16px;
        }
      }
    }
  }

  .business {
    padding: 80px 16px;
    @include sp {
      padding: 48px 16px;
    }
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &:nth-child(odd) {
      .business-inr {
        flex-direction: row-reverse;
        @include sp {
          flex-direction: column-reverse;
        }
        .business-txt {
          margin-right: 0;
          margin-left: percentage(80/1160);
          @include sp {
            margin-left: 0;
          }
        }
      }
    }
    &-inr {
      display: flex;
      width: 100%;
      max-width: 1160px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      @include sp {
        flex-direction: column-reverse;
      }
    }

    &-txt {
      width: percentage(500/1160);
      margin-right: percentage(80/1160);
      @include sp {
        width: 100%;
        margin-right: 0;
      }
      h2 {
        font-size: 2.8rem;
        font-weight: bold;
        @include sp {
          margin-top: 24px;
          font-size: 2.4rem;
          line-height: 1.75;
        }
      }

      p {
        margin-top: 25px;
        font-size: 1.6rem;
        line-height: 1.875;
        @include sp {
          margin-top: 14px;
        }
        .bold {
          display: block;
          font-size: 1.8rem;
          font-weight: bold;
          @include sp {
            margin-bottom: 6px;
            line-height: 1.5;
          }
        }
      }
    }

    &-img {
      width: percentage(580/1160);
      @include sp {
        width: 100%;
      }
    }
  }
}
