@charset "utf-8";

.p-company {

  .mv {
    background-image: url(#{$imgPath}pages/company/mv.jpg);
  }

  .intro {
    @include sp {
      .sec-ttl {
        position: relative;
        z-index: 10;
        margin-top: -40px;
        padding-top: 48px;
        background-color: #fff;
      }

      .sec-l-txt {
        @include sp {
          margin-top: 32px;
          font-size: 2rem;
        }
      }
    }

    ul {
      .sec-l-txt {
        display: inline-block;
        padding-left: 1em;
        text-align: left;
        text-indent: -1em;
        &:nth-child(n+2) {
          margin-top: 0;
        }
      }
    }
  }

  .message {
    padding: 80px 16px;
    background-color: rgba(0,0,0,0.05);
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl,
    .sec-subttl,
    .sec-l-txt {
      text-align: center;
    }
    .sec-l-txt {
      color: #0262b4;
      @include sp {
        margin-top: 32px;
        font-size: 2rem;
        text-align: left;
      }
    }
    .sec-txt {
      margin-top: 32px;
      @include sp {
        font-size: 1.6rem;
        line-height: 1.88;
        text-align: left;
      }
    }
    &-signature {
      margin-top: 34px;
      font-size: 1.6rem;
      text-align: right;
      @include sp {
        margin-top: 32px;
      }
    }
  }

  .company {
    padding: 80px 16px;
    text-align: center;
    @include sp {
      padding: 48px 16px;
    }
    dl {
      margin-top: 40px;
      font-size: 1.6rem;
      text-align: left;
      @include sp {
        margin-top: 32px;
      }
      > div {
        display: flex;
        border-top: 1px solid #dedede;
        &:last-child {
          border-bottom: 1px solid #dedede;
        }
        dt,
        dd {
          padding: 24px 24px 24px 0;
          @include sp {
            padding: 20px 0;
            line-height: 1.88;
          }
        }

        dt {
          width: 100%;
          max-width: 152px;
          @include sp {
            max-width: 123px;
          }
        }
      }
    }
  }

  .technical {
    padding: 80px 16px;
    background-color: rgba(0,0,0,0.05);
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl,
    .sec-subttl {
      text-align: center;
    }
    .sec-txt {
      margin-top: 40px;
      @include sp {
        margin-top: 32px;
      }
    }

    &-jisa {
      display: flex;
      margin-top: 56px;
      padding: 40px;
      background-color: #fff;
      @include sp {
        display: block;
        margin-top: 32px;
        padding: 32px;
      }
      &_img {
        width: 190px;
        margin-right: percentage(40/1080);
        flex-shrink: 0;
        @include sp {
          width: percentage(190/279);
          margin: 0 auto;
        }
      }

      &_txt {
        width: 100%;
      }

      &_ttl {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1;
        @include sp {
          margin-top: 24px;
          font-size: 2.2rem;
          line-height: 1.23;
        }
      }

      p {
        margin-top: 25px;
        font-size: 1.6rem;
        line-height: 1.88;
        @include sp {
          margin-top: 17px;
        }
      }
    }
  }

  .department {
    display: flex;
    margin-top: 56px;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp {
      display: block;
      margin-top: 32px;
    }
    &-col {
      width: percentage(550/1160);
      @include sp {
        width: 100%;
      }
      &:nth-child(n+3) {
        margin-top: 56px;
        @include sp {
          margin-top: 0;
        }
      }
      &:nth-child(n+2) {
        @include sp {
          margin-top: 32px;
        }
      }
    }

    h3 {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1;
    }

    p {
      margin-top: 25px;
      font-size: 1.6rem;
      line-height: 1.88;
    }
  }

  .access {
    padding: 80px 16px;
    background-color: #000;
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl {
      color: #fff;
    }

    .sec-ttl,
    .sec-subttl {
      text-align: center;
    }

    .map {
      margin-top: 40px;
      padding: 40px;
      background-color: #fff;
      @include sp {
        margin-top: 32px;
        padding: 40px 24px;
      }
      &-inr {
        display: flex;
        @include sp {
          display: block;
        }
      }

      &-col {
        width: 48%;
        @include sp {
          width: 100%;
        }
        &:first-child {
          margin-right: 2%;
          @include sp {
            margin-right: 0;
            margin-bottom: 24px;
          }
        }
        h3 {
          font-size: 2.8rem;
          font-weight: bold;
          line-height: 1;
          @include sp {
            line-height: 1.43;
          }
        }

        p {
          margin-top: 16px;
          font-size: 1.6rem;
          @include sp {
            line-height: 1.88;
          }
        }
      }

      &-Gmap {
        width: 100%;
        height: 334px;
        margin-top: 40px;
        @include sp {
          margin-top: 24px;
        }
      }
    }

    &-info {

      &_item {
        display: flex;
        padding: 40px 0;
        @include sp {
          display: block;
          padding: 24px 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-top: 1px solid #dedede;
          @include sp {
            padding-top: 0;
            border-top: none;
          }
        }
      }

      dt {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 180px;
        margin-right: percentage(40/1080);
        padding: 30px;
        background-color: #f2f2f2;
        justify-content: center;
        align-items: center;
        @include sp {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          max-width: 100%;
          margin-right: 0;
          padding: 16px;
        }
      }

      &_icon {
        width: 24px;
        margin: 0 auto;
        @include sp {
          margin: 0 13px 0 0;
        }
      }

      &_txt {
        margin-top: 8px;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.5;
        @include sp {
          margin-top: 0;
        }
      }

      &_img {
        display: flex;
        align-items: center;
        width: percentage(700/1080);
        @include sp {
          width: 100%;
          margin-top: 16px;
        }
        img {
          vertical-align: bottom;
        }
      }

    }
  }

  .history {
    padding: 80px 16px 62px;
    @include sp {
      padding: 48px 16px;
    }
    .sec-ttl {
      text-align: center;
    }
    .sec-subttl {
      text-align: center;
      @include sp {
        margin-top: 22px;
      }
    }


    &-table {
      position: relative;
      margin-top: 18px;
      @include sp {
        display: block;
        margin-top: 28px;
        padding-left: 44px;
      }
      &::before {
        content:"";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 93%;
        transform: translateY(-50%);
        background-color: #0262b4;
        @include sp {
          content: none;
        }
      }

      tr {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        @include sp {
          &:not(:last-child){
            .detail {
              margin: 7px 0 20px;
            }
            &::before {
              content:"";
              position: absolute;
              top: 20px;
              left: -34px;
              width: 1px;
              height: 100%;
              background-color: #0262b4;
            }
          }
        }
      }

      .year {
        position: relative;
        font-family: $roboto;
        font-size: 3.2rem;
        font-weight: 900;
        line-height: 1;
        vertical-align: middle;
        @include sp {
          display: inline-block;
          line-height: 1.25;
        }
        &::before {
          content:"";
          position: absolute;
          top: 50%;
          left: -10px;
          width: 20px;
          height: 20px;
          transform: translateY(-50%);
          border-radius: 50%;
          background-color: #0262b4;
          @include sp {
            left: -44px;
          }
        }
      }

      .gengo {
        font-family: $roboto;
        font-size: 1.6rem;
        font-weight: 900;
        vertical-align: middle;
        color: #0262b4;
        @include sp {
          display: inline-block;
          margin-left: 24px;
          line-height: normal;
        }
      }

      .detail {
        font-size: 1.6rem;
        vertical-align: middle;
        @include sp {
          display: block;
          line-height: 1.88;
        }
      }

      .year,
      .gengo,
      .detail {
        padding:18px 0 18px 34px;
        @include sp {
          padding: 0;
        }
      }
    }
  }
}
