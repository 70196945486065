@charset "utf-8";

.js-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  height: auto;
}


.modaal-close {
  &::before,
  &::after {
    border-radius: 0 !important;
  }
}
//動画モーダルの閉じるボタンの色変更
.modaal-close:hover:before,
.modaal-close:hover:after {
  background: #000 !important;
}
.modaal-inner-wrapper {
  overflow: hidden;
}

//動画モーダルのSPサイズ調整
.modaal-video-wrap {
  margin: 0 auto !important;
}
.modaal-gallery-control {
  @include sp {
    top: 50% !important;
    transform: translateY(-50%) scale(0.7) !important;
  }
  &:focus,
  &:hover {
    &:before,
    &:after {
      transition: none !important;
      background: #000 !important;
    }
  }
}
.modaal-gallery-next {
  @include sp {
    right: 0 !important;
  }
}

.modaal-gallery-prev {
  @include sp {
    left: 0 !important;
  }
}

[data-aos="slide-text"] {
  overflow: hidden;
  width: 0;
  white-space: nowrap;
}

[data-aos="slide-text"].aos-animate {
  animation: flowing-anim 1.4s forwards linear;
}

@keyframes flowing-anim {
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}
