@charset "utf-8";

html {
  overflow: visible;
}

.clearfix {
  @include clearfix();
}

.container {
  @include cwLayout();
}

a {
  transition: all.3s;
  &:hover {
    opacity: 0.7;
  }
}

main {
  margin-top: 100px;
  @include mq(large) {
    margin-top: 70px;
  }
}

.is-pc {
  display: block;
  @include sp {
    display: none;
  }
}

.is-sp {
  display: none;
  @include sp {
    display: block;
  }
}

.is-tab {
  display: none;
  @include mq(large) {
    display: block;
  }
}

.is-pctab {
  display: block;
  @include mq(large) {
    display: none;
  }
}
