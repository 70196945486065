@charset "utf-8";

.button {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .button-body {
    position: relative;
    display: flex;
    width: 100%;
    transition: opacity 0.3s;
    color: #fff;
    background-color: $btnColor;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 21px;
      width: 10px;
      height: 10px;
      transition: all .3s;
      transform: translateY(-50%) rotate(45deg);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }

    &:hover {
      &::before {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
    &:hover {
      opacity: 0.7;
    }

    span {
      font-family: $archivo;
      font-weight: bold;
    }
  }

  // size
  &-size {
    &--small {
      max-width: 240px;

      .button-body {
        height: 50px;

        span {
          font-weight: 1.6rem;
        }
      }
    }

    &--medium {
      max-width: 360px;

      .button-body {
        height: 80px;
        @include sp {
          height: 76px;
        }
        span {
          font-weight: 2rem;
        }
      }
    }

    &--large {
      max-width: 430px;
      .button-body {
        height: 80px;

        @include mq() {
          // height: 100px;
        }

        span {
          font-size: 2rem;
        }
      }
    }
  }
}


.sec {

  &-inr {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
  }

  &-ttl {
    font-family: $archivo;
    font-size: 4.8rem;
    line-height: 1;
    @include sp {
      text-align: center;
    }
  }

  &-subttl {
    margin-top: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: $subttlColor;
    @include sp {
      margin-top: 24px;
      text-align: center;
    }
  }

  &-l-txt {
    margin-top: 40px;
    font-size: 2.4rem;
    line-height: 1.75;
  }

  &-txt {
    font-size: 1.6rem;
    line-height: 1.875;
  }

  &-btn {
    position: relative;
    display: block;
    max-width: 240px;
    padding: 16px 0;
    font-family: $archivo;
    font-size: 1.6rem;
    line-height: 1;
    text-align: center;
    background-color: $btnColor;
    @include sp {
      padding: 17px 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 21px;
      width: 10px;
      height: 10px;
      transition: all .3s;
      transform: translateY(-50%) rotate(45deg);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }

    &:hover {
      &::before {
        transform: translate(3px, -50%) rotate(45deg);
      }
    }
  }
}

//下層ページのMV,パンくず,MV下のテキスト
.p-child {

  .mv {
    position: relative;
    display: flex;
    min-height: 380px;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    align-items: center;
    @include sp {
      height: 200px;
      min-height: auto;
      padding-bottom: 0;
      background-position: center center;
      background-size: cover;
    }

    &-ttl {
      display: block;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
      font-family: $archivo;
      font-size: 6.4rem;
      font-weight: 400;
      color: #fff;
      @include sp {
        position: absolute;
        top: 40px;
        padding: 0 30px;
        font-size: 4.8rem;
        line-height: 1;
      }
      span {
        display: inline-block;
        margin-left: 24px;
        font-family: $baseFont;
        font-size: 1.8rem;
        font-weight: bold;
        @include sp {
          display: block;
          margin-top: 6px;
          margin-left: 0;
          font-size: 1.6rem;
          letter-spacing: normal;
        }
      }
      &.black {
        color: #000;
      }
    }

    .breadcrumb {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: flex;
      width: 100%;
      max-width: 1160px;
      margin: 0 auto;
      padding: 27px 32px;
      line-height: 2;
      transform: translateX(-50%);
      color: #000;
      background-color: #fff;
      @include sp {
        display: none;
      }

      li {
        position: relative;
        padding: 0 1rem;
        font-size: 1.3rem;
        list-style-type: none;
        color: #333;
        &:first-child {
          padding-left: 0;
        }

        &:nth-child(n+2) {
          padding-left: 2rem;
          &::before {
            content:">";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        a {
          display: inline-block;
          color: #333;
        }
      }
    }
  }

  .intro {
    max-width: 1200px;
    margin: 0 auto;
    padding:0 20px 80px;
    text-align: center;
    background-color: #fff;
    @include sp {
      padding: 0 16px 48px;
    }
    &-ttl {
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      @include sp {
        position: relative;
        z-index: 2;
        margin-top: -40px;
        padding-top: 48px;
        font-size: 2.8rem;
        background-color: #fff;
      }

      .small {
        font-size: 2.4rem;
        font-weight: bold;
        @include sp {
          font-size: 1.8rem;
        }
      }
    }

    &-txt {
      margin-top: 42px;
      font-size: 1.6rem;
      line-height: 1.875;
      text-align: left;
      @include sp {
        margin-top: 33px;
      }
    }
  }
}
