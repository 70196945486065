@charset "utf-8";
.p-contact {
  padding-bottom: 120px;
  @include sp {
    padding-bottom: 48px;
  }
  .mv {
    background-image: url(#{$imgPath}pages/contact/mv.jpg);

    &-ttl {
      @include sp {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .intro {
    padding-bottom: 58px;
    @include sp {
      padding: 48px 16px;
    }
    &-txt {
      margin-top: 16px;
      text-align: center;
      &:not(:first-child) {
        margin-top: 1.88em;
      }
      .red {
        color: #bf0b0b;
      }

      a {
        text-decoration: underline;
        color: #0262b4;
      }
    }
  }

  .contactForm {
    display: block;
    width: 100%;

    &-wrapper {
      padding: 16px 40px;
      background-color: #f2f2f2;
      @include sp {
        padding: 16px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 24px 0 24px;
      @include sp {
        display: block;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #dedede;
      }

      input,
      textarea {
        display: inline-block;
        padding: 16px;
        font-size: 1.6rem;
        color: #000;
        border: 2px solid #f2f2f2;
        outline: 0;
        @include sp {
          padding: 12px;
        }
        &:focus {
          border: 2px solid #0262b4;
          border-radius: 0;
          outline: 0;
        }

        &::placeholder {
          font-size: 1.6rem;
          font-weight: normal;
          line-height: 1.88;
          color: #a0a0a0;
        }
      }


      &__ttl {
        width: 278px;
        font-size: 1.6rem;
        font-weight: normal;
        text-align: left;
        vertical-align: middle;
        @include sp {
          width: 100%;
        }
        &.required {
          position: relative;
          &::after {
            content:"必須";
            margin-left: 16px;
            padding: 2px 8px;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            background-color: #bf0b0b;
          }
        }
      }

      &__supplement {
        display: block;
        margin-bottom: 8px;
        font-size: 1.6rem;
        line-height: 1.88;
        &:nth-child(n+2) {
          margin-top: 16px;
        }
      }

      &__checkboxArea-inr {
        display: flex;
        column-gap: percentage(56/800);
        @include sp {
          flex-wrap: wrap;
          column-gap: normal;
          row-gap: 12px;
        }
      }
      &__checkboxArea-item {
        font-size: 1.6rem;
        @include sp {
          display: inline-block;
          width: 50%;
          font-size: 1.6rem;
        }
        input[type=checkbox] {
          display: none;
          width: 24px;
          height: 24px;
          @include sp {
            width: 20px;
            height: 20px;
          }
          &:checked + label {

            &::before {
              background-color: #0262b4;
            }

            &::after {
              content:"";
              position: absolute;
              top: 50%;
              left: 6px;
              width: 12px;
              height: 9px;
              transform: translateY(-50%);
              background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%229.368%22%20viewBox%3D%220%200%2012%209.368%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_778%22%20data-name%3D%22%E3%83%91%E3%82%B9%20778%22%20d%3D%22M105.531%2C47.75l-1.617%2C1.617L106.546%2C52l1.617%2C1.617L109.78%2C52l6.134-6.133L114.3%2C44.249l-6.133%2C6.133Z%22%20transform%3D%22translate(-103.914%20-44.249)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
              background-repeat: no-repeat;
              background-size: contain;
              @include sp {
                left: 5px;
                width: 10px;
              }
            }
          }
        }
        label {
          position: relative;
          padding-left: 40px;
          @include sp {
            padding-left: 30px;
          }
          &::before {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid #0262b4;
            border-radius: 8px;
            @include sp {
              top: 50%;
              width: 20px;
              height: 20px;
              transform: translateY(-50%);
            }
          }
        }
      }

      dd {
        width: percentage(800/1080);
        @include sp {
          width: 100%;
          margin-top: 12px;
        }
      }

      .input {
        &-short {
          width: 120px;
          @include sp {
            width: 110px;
          }
        }

        &-2col {
          &-short {
            width: percentage(132/800);
            @include sp {
              width: 25%;
            }
          }

          &-medium {
            width: percentage(300/800);
            @include sp {
              width: 40%;
            }
          }
        }

        &-w100 {
          width: 100%;
        }
      }

      .textarea {
        width: 100%;
        height: 200px;
        resize: none;
        color: #000;
      }
    }

    .submit {
      margin-top: 56px;
      padding: 0 16px;

      &-btn {
        font-size: 2rem;
        font-weight: bold;
        border: none;
      }
    }

    .errorTxt {
      font-weight: bold;
      color: red;
    }

    .errorBg {
      border: 2px solid red;
    }
  }

  .contact-confirm {
    margin-top: 80px;
    .button {
      display: flex;
      justify-content: space-between;
      max-width: 560px;
      @include sp {
        display: block;
        margin: 48px auto 0;
      }
      &-body {
        width: 100%;
        max-width: 240px;
        @include sp {
          margin: 0 auto;
          &:nth-child(n+2) {
            margin-top: 16px;
          }
        }
        &.back {
          &:hover::before {
            transform: translate(-3px,-50%) rotate(-135deg);
          }
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 21px;
            width: 10px;
            height: 10px;
            transition: all .3s;
            transform: translateY(-50%) rotate(-135deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
          }
        }
      }
    }
  }
}
