@charset "utf-8";

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 28px 40px 25px 35px;
  background-color: $baseColor;
  justify-content: space-between;
  align-items: center;
  @include mq(large) {
    padding: 22px 16px;
  }

  &-logo {
    max-width: 340px;
    @include mq(large) {
      width: 233px;
    }
  }

  .gnav {
    @include mq(large) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 100%;
      overflow-y: auto;
      width: 100%;
      margin-top: 70px;
      transition: all.6s;

      &.is-show {
        left: 0;
      }
    }
    &-nav {
      display: flex;
      column-gap: 40px;
      @include mq(large) {
        display: block;
      }
    }

    &-list {
      &:not(:last-child) {
        @include mq(large) {
          margin-right: 0;
          border-bottom: 1px solid rgba(222,222,222,0.8);
        }
      }
    }

    &-link {
      position: relative;
      display: block;
      padding-top: 34px;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1;
      cursor: pointer;
      color: $linkColor;
      background-color: rgba(0,0,0,0.8);
      @include mq(large) {
        padding: 16px 30px;
        font-size: 1.6rem;

        &::after {
          content:"";
          position: absolute;
          top: 50%;
          right: 21px;
          width: 10px;
          height: 10px;
          transition: all .3s;
          transform: translateY(-50%) rotate(45deg);
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
      &::before {
        content:"";
        position: absolute;
        top: 0;
        left: 50%;
        width: 6px;
        height: 6px;
        transform: translateX(-50%);
        background-color: #fff;

        @include mq(large) {
          top: 50%;
          left: 16px;
          transform: translate(0, -50%);
        }
      }
    }

    .submenu {
      //hover時のスタイル
      @include pc {
        position: absolute;
        top: 100%;
        left: 50%;
        visibility: hidden;
        margin: 0 auto;
        padding-top: 22px;
        // transition: all .2s ease;
        transform: translateX(-50%);
        white-space: nowrap;
        opacity: 0;
        background-color: #000;
        &:hover {
          opacity: 0.7;
        }
        &-list:nth-child(n+2) {
          border-top: 1px solid #555;
        }

        &-link {
          display: block;
          padding: 12px 24px;
          color: #fff;
        }
      }

      //アコーディオン内のスタイル
      @include mq(large) {
        display: none;
        background-color: #000;
        &-list {
          border-top: 1px solid #555;
        }

        &-link {
          display: block;
          padding: 16px 30px;
          color: #99a0a2;
        }
      }
    }

    .submenu-btn {
      position: relative;
      .gnav-link {
        &::after {
          transform: translateY(-50%) rotate(135deg);
        }
      }

      .is-show.gnav-link {
        &::after {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
      &:hover {
        .submenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .hamburger {
    display: none;
    @include mq(large) {
      position: relative;
      display: block;
      width: 32px;
      height: 26px;

      .bar {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        transition: all.3s;
        background-color: #fff;
        &.top {
          top: 0;
          left: 0;
        }

        &.middle {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &.bottom {
          bottom: 0;
          left: 0;
        }
      }

      &.is-active {
        .bar {
          &.top {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }

          &.middle {
            opacity: 0;
          }

          &.bottom {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
    }
  }
}
