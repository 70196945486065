@charset "utf-8";

.footer {
  padding: 60px 16px 20px;
  color: #fff;
  background-color: $baseColor;
  @include mq(large) {
    padding: 32px 16px;
  }

  .wrapper {
    display: flex;
    max-width: 1120px;
    margin: 0 auto;
    justify-content: space-between;
    @include mq(large) {
      display: block;
    }
  }

  &-info {
    max-width: 264px;
    @include  mq(large) {
      max-width: 100%;
    }

    &_logo {
      padding-bottom: 12px;
      border-bottom: 1px solid #fff;
      img {
        @include mq(large) {
          width: 268px;
        }
      }
    }
    address {
      margin-top: 12px;
      font-size: 1.2rem;
      font-weight: 500;
      font-style: normal;
      line-height: 1.75;
    }
  }

  &-nav {
    display: flex;
    width: percentage(682/1120);
    max-width: 682px;
    @include mq(large) {
      display: none;
    }
    ul:not(:last-child){
      margin-right: percentage(60/1120);
    }

    li {
      position: relative;
      padding-left: 14px;
      font-weight: 500;
      &:first-child {
        margin-bottom: 16px;
      }

      a {
        display: block;
      }
    }

    .bold {
      font-weight: 800;
    }

    .arrow {
      &::before {
        content:"";
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        height: 5px;
        transform: translate(-50%, -50%) rotate(45deg);
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
      }
    }

    .dotted {
      &::before {
        content:"";
        position: absolute;
        top: 50%;
        left: 0;
        width: 2px;
        height: 2px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  .copyright {
    margin-top: 57px;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    @include mq(large) {
      margin-top: 27px;
      font-size: 1rem;
    }
  }
}
