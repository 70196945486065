@charset "utf-8";
.p-privacyPolicy {
  padding-bottom: 120px;
  @include sp {
    padding-bottom: 48px;
  }

  .mv {
    background-image: url(#{$imgPath}pages/privacy/mv.jpg);

    &-ttl {
      @include sp {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .intro {
    padding-bottom: 58px;
    @include sp {
      padding-bottom: 32px;
    }
    &-txt {
      text-align: center;
    }
  }

  .privacyPolicy {
    padding: 0 16px;
    .sec-inr {
      padding: 60px 0;
      border-top: 1px solid #dedede;
      @include sp {
        padding: 32px 0;
      }
    }

    .sec-ttl {
      font-family: $baseFont;
      font-size: 2.8rem;
      font-weight: bold;
      @include sp {
        font-size: 2.4rem;
        line-height: 1.5;
        text-align: left;
      }
    }

    .sec-txt {
      margin-top: 28px;
      font-size: 1.6rem;
      line-height: 1.88;

      a {
        text-decoration: underline;
        color: #0262b4;
      }
    }

    .sec-list {
      margin-top: 28px;

      li {
        padding-left:1em;
        font-size: 1.6rem;
        line-height: 1.88;
        text-indent:-1em;
      }
    }
  }

  .button {
    margin-top: 20px;

    &-body > span {
      font-family: $baseFont;
      font-size: 1.6rem;
    }
  }
}
