@charset "utf-8";
.p-products {

  .mv {
    background-image: url(#{$imgPath}pages/products/mv.jpg);
  }

  .products {
    padding: 80px 16px;
    @include sp {
      padding: 48px 16px;
    }
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &-inr {
      display: flex;
      width: 100%;
      max-width: 1160px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      @include sp {
        flex-direction: column-reverse;
      }
    }

    &-txt {
      width: percentage(500/1160);
      margin-right: percentage(80/1160);
      @include sp {
        width: 100%;
        margin-right: 0;
      }
      .sec-ttl {
        font-family: $baseFont;
        font-size: 2.8rem;
        font-weight: bold;
        @include sp {
          margin-top: 24px;
          font-size: 2.4rem;
          line-height: 1.5;
          text-align: left;
        }
      }

      .sec-subttl {
        margin-top: 17px;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.875;
        color: #000;
        @include sp {
          margin-top: 9px;
          font-size: 1.8rem;
          line-height: 1.5;
          text-align: left;
        }
      }

      p {
        margin-top: 25px;
        font-size: 1.6rem;
        @include sp {
          margin-top: 17px;
          line-height: 1.88;
        }
      }
    }

    .sec-btn {
      margin-top: 32px;
      font-weight: bold;
      @include sp {
        margin-top: 26px;
      }
    }

    &-img {
      width: percentage(580/1160);
      @include sp {
        width: 100%;
      }
    }
  }
}
