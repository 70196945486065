@charset "utf-8";
.p-factory {

  .mv {
    background-image: url(#{$imgPath}pages/factory/mv.jpg);
  }

  .factory {
    padding: 80px 16px;
    background-color: rgba(0,0,0, 0.05);
    @include sp {
      padding: 48px 16px;
    }

    &-2col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include sp {
        flex-direction: column-reverse;
      }
      &__txt {
        width: percentage(500/1160);
        @include sp {
          width: 100%;
        }
        h2 {
          font-size: 2.8rem;
          font-weight: bold;
          @include sp {
            margin-top: 24px;
            font-size: 2.4rem;
            line-height: 1.67;
          }
        }
        p {
          margin-top: 25px;
          font-size: 1.6rem;
          line-height: 1.88;
          @include sp {
            margin-top: 12px;
          }
        }
      }

      &__img {
        width: 50%;
        @include sp {
          width: 100%;
        }
      }
    }

    &-3col {
      display: flex;
      margin-top: 80px;
      flex-wrap: wrap;
      row-gap: 40px;
      column-gap: percentage(40/1160);
      @include sp {
        display: block;
        margin-top: 40px;
      }
      li {
        width: percentage(360/1160);
        @include sp {
          width: 100%;
          &:nth-child(n+2) {
            margin-top: 24px;
          }
        }
        p {
          margin-top: 16px;
          font-size: 1.6rem;
          line-height: 1.69;
          text-align: center;
          @include sp {
            margin-top: 12px;
          }
        }
      }
    }
  }

}
